<template>
  <div class="">
    <div class="row justify-content-between align-items-end">
      <div class="col row">
        <div class="col-3">
          <label for="">Dépôt <span class="text-danger">*</span> </label>
          <select
            v-model="deposit_reference"
            @change="updateFilteredOutStocks"
            class="form-select"
          >
            <option value="">---</option>
            <option
              v-for="(deposit, index) in deposits"
              :key="index++"
              :value="deposit.reference"
            >
              {{ deposit.fullName }}
            </option>
          </select>
        </div>
        <div class="col-4">
          <label for="">Catégorie</label>
          <select
            v-model="section_reference"
            @change="updateFilteredOutStocks"
            class="form-select"
          >
            <option value="">---</option>
            <option
              v-for="(section, index) in sections.filter(
                (p) =>
                  p.deposit_reference != null &&
                  p.deposit_reference == deposit_reference
              )"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
        </div>

        <div class="col-4">
          <label for="">Produit</label>
          <select
            v-model="item_reference"
            @change="updateFilteredOutStocks"
            class="form-select"
          >
            <option value="">---</option>
            <option
              v-for="(item, index) in items.filter(
                (p) => p.section_reference == section_reference
              )"
              :key="index++"
              :value="item.reference"
            >
              {{ item.fullName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise fs-5"></i>
          <span> Actualiser </span>
        </button>
      </div>

      <div class="col-auto">
        <button @click="print()" class="btn btn-secondary">
          <i class="bi bi-printer fs-5"></i>
          <span> Imprimer </span>
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Désignation</th>
          <th scope="col">Catégorie</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in filteredOutStocks" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            <button
              class="btn btn-link ms-2"
              @click="
                $router.push({
                  name: 'profile-stock',
                  params: {
                    reference: item.reference,
                  },
                })
              "
            >
              {{ item.reference }}
            </button>
          </td>
          <td>
            <div>
              {{ item.fullName }}
            </div>
          </td>
          <td>
            <div v-if="item.section">
              {{ item.section.fullName }}
            </div>
          </td>

          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'stocks-create',
                  params: { itemReference: item.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-plus-square fs-5 text-success"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",

      section_reference: "",
      item_reference: "",
      deposit_reference: "",
      filteredOutStocks: [],
    };
  },
  computed: {
    ...mapGetters("stock", {
      out_stocks: "getOutStocks",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("deposit", {
      deposits: "getAll",
    }),
  },
  async mounted() {
    this.$store.dispatch("stock/getAllOut");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("deposit/getAll");
    this.$store.dispatch("item/getAll");
  },
  methods: {
    async refresh() {
      this.$store.dispatch("stock/getAllOut");
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("deposit/getAll");

      this.search_value = "";
      this.addedToday = false;
      this.sortValue = "";
      this.section_reference = "";
      this.item_reference = "";
      this.deposit_reference = "";
    },
    async updateFilteredOutStocks() {
      // Call the filtering logic again to update the filteredOutStocks
      if (this.deposit_reference == "---") {
        return (this.filteredOutStocks = this.out_stocks);
      }
      // Check if all three filters (item_reference, section_reference, deposit_reference) are present
      if (
        this.item_reference != "" &&
        this.section_reference != "" &&
        this.deposit_reference != ""
      ) {
        // Filter the out_OutStocks array based on the selected item_reference
        return (this.filteredOutStocks = this.out_stocks.filter((stock) => {
          return stock.item_reference == this.item_reference;
        }));
      }

      // Check if both section_reference and deposit_reference filters are present
      else if (this.section_reference != "" && this.deposit_reference != "") {
        // Filter the OutStocks array based on either the selected section_reference or deposit_reference
        return (this.filteredOutStocks = this.out_stocks.filter((stock) => {
          return (
            stock.section_reference == this.section_reference ||
            stock.deposit_reference == this.deposit_reference
          );
        }));
      }

      // Check if only deposit_reference filter is present
      else if (this.deposit_reference != "") {
        // Filter the OutStocks array based on the selected deposit_reference
        return (this.filteredOutStocks = this.out_stocks.filter((stock) => {
          return stock.deposit_reference == this.deposit_reference;
        }));
      }

      // Return an empty array if none of the filters are present
      else return [];
    },
    async sort(value) {
      return await this.$store.commit("stock/sort", value);
    },
  },
};
</script>
